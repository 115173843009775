import React, {Component} from "react"
import styled from 'styled-components'
import {media} from "utils/Media"
import {Container} from 'reactstrap'

import {graphql, StaticQuery} from 'gatsby'
import Layout from "components/Layout/Layout"
import KalturaModal from "components/shared/KalturaModal"
import HeaderCopy from "components/shared/HeaderCopy"
import {GatsbyImage} from "gatsby-plugin-image"
import BtnPlay from 'images/icons/btn-play.svg'

import LOtC from 'images/LOtC-QB-logo-high-res.png'

const VideoWrapper = styled.button`
    width: 100%;
    max-width: 570px;
    margin: 0 auto;
    cursor: pointer;
    position: relative;
    padding: 0;
    
    img {
        padding-left: 0 !important;
    }
    
    .video-overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: black;
        opacity: 0.2;
        transition: opacity 200ms ease-in-out;
        z-index: 2;
    }
    
    .play-btn {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        opacity: 0.5;
        transition: opacity 200ms ease-in-out;
        z-index: 3;
        width: 80px;
        height: 80px;
    }
    
    &:hover {
        .video-overlay {
            opacity: 0.5;
        }
        
        .play-btn {
            opacity: 1;
        }
    }
`

const BlockSection = styled.div`
    @media ${media.lg} {
        display: flex;
        align-items: stretch;
        justify-content: center;
    }
`

const Block = styled.div`
    margin-bottom: 1rem;
    border-bottom: none;
    width: 100%;
    
    @media ${media.lg} {
        width: 50%;
        border-bottom: none;
        margin-bottom: 0;
        padding-bottom: 0;
        
        &.border {
            &-br {
                border-bottom: 1px solid ${props => props.theme.colors.lightGrey};
                border-right: 1px solid ${props => props.theme.colors.lightGrey};
                
                &:hover {
                    background: linear-gradient(135deg, #FFFFFF 71.34%, #F9F9F9 100%);
                }
            }
            
            &-bl {
                border-bottom: 1px solid ${props => props.theme.colors.lightGrey};
                border-left: 1px solid ${props => props.theme.colors.lightGrey};
                
                &:hover {
                    background: linear-gradient(225deg, #FFFFFF 71.34%, #F9F9F9 100%);
                }
            }
            
            &-tr {
                border-top: 1px solid ${props => props.theme.colors.lightGrey};
                border-right: 1px solid ${props => props.theme.colors.lightGrey};
                
                &:hover {
                    background: linear-gradient(45deg, #FFFFFF 71.34%, #F9F9F9 100%);
                }
            }
            
            &-tl {
                border-top: 1px solid ${props => props.theme.colors.lightGrey};
                border-left: 1px solid ${props => props.theme.colors.lightGrey};
                
                &:hover {
                    background: linear-gradient(315deg, #FFFFFF 71.34%, #F9F9F9 100%);
                }
            }
        }
        
        &.padding {
            &-br {
                padding-bottom: 2rem;
                padding-right: 2rem;
            }
            
            &-bl {
                padding-bottom: 2rem;
                padding-left: 2rem;
            }
            
            &-tr {
                padding-top: 2rem;
                padding-right: 2rem;
            }
            
            &-tl {
                padding-top: 2rem;
                padding-left: 2rem;
            }
        }
    }
    
    .yellow-bg {
        padding: 2rem;
        background: linear-gradient(180deg, rgba(243, 206, 18, 0.15) 0%, rgba(243, 206, 18, 0) 100%), #FFF8E1;
    }
    
    h2 {
        margin-top: 0;
    }
`

const Query = () => (
    <StaticQuery
        query={graphql`
            query {
                WorkshopVideoPoster: file(relativePath: { eq: "WorkshopPage/workshop-video-poster.jpg" }) {
                    childImageSharp {
                        gatsbyImageData(width: 570, quality: 100)
                    }
                }
            }
		`}
        render={data => (
            <WorkshopsPage data={data}/>
        )}
    />
)

class WorkshopsPage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            modalOpen: false,
        }
    }

    componentDidMount() {

    }

    toggleModal = (e, src) => {
        e.preventDefault();

        this.setState({
            src: src,
            modalOpen: !this.state.modalOpen
        });
    }

    render() {

        return (
            <Layout slug="workshops" title="Workshops">
                <HeaderCopy title="Workshops"/>

                <Container fluid={true}>
                    <Container>
                        <BlockSection>
                            <Block className="padding-br">
                                <h2>Welcome to the Workshops</h2>
                                <p className="bigger">Disney’s official THE LION KING workshops are the perfect accompaniment for your school’s visit to the show.</p>

                                <p>Led by specifically trained Disney teaching artists, workshops can be tailored to your group’s needs and give your students the rare opportunity to perform scenes, songs and dances from the award-winning musical whilst developing core skills such as communication and team building in a unique and creative way.</p>

                                <p>Workshops cost £14 per person on top of the ticket price and last 90 minutes. In London, they take place in a venue near the Lyceum Theatre, on the tour they take place at the participants’ school. They are suitable for those aged 7 and up; minimum group size is 25.</p>

                                <p>To book or to find out more information, call <strong><a href="tel:+442078450949">020 7845 0949</a></strong> or <a style={{textDecoration: "underline", fontWeight: 300}} href="https://groupbooking.disneytickets.co.uk/contact/educational-workshop-enquiry-form/" target="_blank" rel="noreferrer noopener">send an enquiry</a> now.</p>
                            </Block>

                            <Block className="padding-bl" style={{textAlign: "center"}}>
                                <VideoWrapper onClick={(e) => this.toggleModal(e, "https://secure.disney.com/embed/58fae8abee5237385adda963?domain=www.disney.co.uk")} aria-label="Open video" role="button" tabindex="0">
                                    <GatsbyImage image={this.props.data.WorkshopVideoPoster.childImageSharp.gatsbyImageData}
                                        alt=""
                                        title=""
                                    />
                                    <div className="video-overlay"/>
                                    <div className="play-btn">
                                        <img src={BtnPlay} alt="" className="img-fluid"/>
                                    </div>
                                </VideoWrapper>
                            </Block>
                        </BlockSection>

                        <BlockSection>
                            <Block className="padding-br">
                                <img src={LOtC} alt="" className="img-fluid mx-auto"/>
                            </Block>
                        </BlockSection>


                        <BlockSection>
                            <Block className="border-br padding-br">
                                <div className="yellow-bg">
                                    <h2>The Rhythm of the Pridelands</h2>
                                    <p className="bigger bold">Movement and Music Workshop</p>
                                    <p className="bigger">Untame your imagination and perform material from Disney’s THE LION KING!</p>
                                    <p>Teaching artists will transport you from a West End studio to the African savannah. Explore one-of-a-kind choreography and music – inspired by the evocative rhythms of Africa – from the award-winning show.</p>
                                </div>
                            </Block>

                            <Block className="border-bl padding-bl">
                                <div className="yellow-bg">
                                    <h2>Spotlight on Acting</h2>
                                    <p className="bigger bold">Polish Your Acting Skills</p>
                                    <p className="bigger">Explore the journey of Simba, the lion cub who escapes to the worry-free jungle to avoid problems in the Pridelands.</p>
                                    <p>In this engaging workshop you will use scenes from the West End script to explore characterisation techniques, investigate how actors use voice and movement to tell a story and develop your own take on characters.</p>
                                </div>
                            </Block>
                        </BlockSection>

                        <BlockSection>
                            <Block className="border-tr padding-tr">
                                <div className="yellow-bg">
                                    <h2>Spotlight On Singing</h2>
                                    <p className="bigger bold">Intermediate to Advanced Singing Workshop</p>
                                    <p className="bigger">Explore the unique musical style of THE LION KING, learn to sing as a cohesive ensemble as you incorporate movement and acting to enrich the storytelling.</p>
                                </div>
                            </Block>

                            <Block className="border-tl padding-tl">
                                <div className="yellow-bg">
                                    <h2>Spotlight On Dancing</h2>
                                    <p className="bigger bold">Explore Intricate Choreography</p>
                                    <p className="bigger">Experience the incredible choreography from show, in this dance-focused workshop. Layer in singing and acting as you step into the shoes of a West End performer.</p>
                                </div>
                            </Block>
                        </BlockSection>
                    </Container>
                </Container>

                <KalturaModal toggleModal={this.toggleModal} src={this.state.src} modalOpen={this.state.modalOpen}/>

            </Layout>
        )
    }
}

export default Query
